<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body p-0">
						<div class="mm-edit-list usr-edit">
							<tab-nav :pills="true" extraclass="mm-edit-profile d-flex" >
								<tab-nav-items :active="true" id="personal" dataToggle="pill" ariaControls="personal-information" title="Informasi Personal" liClass=" col-md-6 p-0" />
								<tab-nav-items :active="false" id="profile-pict" dataToggle="pill" ariaControls="prof-pict" title="Ubah Photo" liClass=" col-md-6 p-0" />
							</tab-nav>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-12">
				<div class="mm-edit-list-data">
					<tab-content>
						<tab-content-item :active="true" id="personal-information" aria-labelled-by="personal">
							<div class="card">
								<div class="card-header d-flex justify-content-between">
									<div class="mm-header-title">
										<h4 class="card-title">Informasi Personal Pegawai</h4>
									</div>
								</div>
								<div class="card-header">
									<b-img thumbnail class="mx-auto d-block" v-bind="{width: 150, height: 150}" :src="formProfile.path_foto" alt="Display Picture"></b-img>
								</div>
								<div class="card-body">
									<form>
										<div class="form-row">
											<b-col md="6" class="mb-3">
												<label for="full_name">Nama Lengkap<strong>*</strong></label>
												<input type="text" class="form-control" id="full_name" v-model="formProfile.full_name">
											</b-col>
											<b-col md="6" class="mb-3">
												<label for="nip">NIP</label>
												<input type="number" class="form-control" id="nip" v-model="formProfile.nip">
											</b-col>
											<b-col md="6" class="mb-3">
												<label for="email">Email<strong>*</strong></label>
												<input type="email" class="form-control" id="email" v-model="formProfile.email">  
											</b-col>
											<b-col md="6" class="mb-3">
												<label for="phone">Jabatan<strong>*</strong></label>
												<input type="text" class="form-control" id="jabatan" v-model="formProfile.jabatan">
											</b-col>
											<b-col md="6" class="mb-3">
												<label for="phone">Golongan</label>
												<v-select :options="jabatan" v-model="formProfile.golongan" label="golongan" @input="golonganHandle" class="style-chooser"></v-select>
												<!-- <input type="text" class="form-control" id="golongan" v-model="form.golongan"> -->
											</b-col>
											<b-col md="6" class="mb-3">
												<label for="phone">Pangkat</label>
												<input type="text" class="form-control" id="pangkat" v-model="formProfile.pangkat" readonly>
											</b-col>
											<b-col md="6" class="mb-3">
												<label for="phone">Kontak</label>
												<input type="text" class="form-control" id="phone" v-model="formProfile.phone">
											</b-col>
											<b-col md="6" class="mb-3">
												<label for="tgl_lahir">Tgl. Lahir:</label>
												<flat-pickr class="form-control" :config="config" id="tgl_lahir" v-model="formProfile.tgl_lahir"></flat-pickr>
											</b-col>
											<b-col md="12" class="form-group col-sm-6">
												<label class="d-block">Status Pegawai:</label>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" v-model="formProfile.status_pegawai" id="aktif" value="aktif" class="custom-control-input">
													<label class="custom-control-label" for="aktif"> Aktif </label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" v-model="formProfile.status_pegawai" value="pensiun" id="pensiun" class="custom-control-input">
													<label class="custom-control-label" for="pensiun"> Pensiun </label>
												</div>
											</b-col>
											<b-col md="12" class="form-group col-sm-6">
												<label class="d-block">Jenis Kelamin:</label>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" v-model="formProfile.jenis_kelamin" id="laki" value="Laki-laki" class="custom-control-input">
													<label class="custom-control-label" for="laki"> Laki-Laki </label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" v-model="formProfile.jenis_kelamin" value="Perempuan" id="perempuan" class="custom-control-input">
													<label class="custom-control-label" for="perempuan"> Perempuan </label>
												</div>
											</b-col>
											<b-col md="12" class="mb-3">
												<label for="address">Alamat</label>
												<textarea class="form-control" v-model="formProfile.address" id="address"></textarea>
											</b-col>
										</div>
										<div class="float-right">
											<button type="submit" class="btn bg-danger mr-1" @click.prevent="formCancel()">Batal</button>
											<button type="submit" class="btn btn-primary" @click.prevent="updateProfile()">Simpan</button>
										</div>
									</form>
								</div>
							</div>
						</tab-content-item>
            <tab-content-item :active="false" id="prof-pict" aria-labelled-by="profile-pict">
              <div class="card">
                <div class="card-header d-flex justify-content-between">
                  <div class="mm-header-title">
                    <h4 class="card-title">Ubah Poto</h4>
                  </div>
                </div>
                <div class="card-body">
                  <form>
                  <div class="form-row">
                    <b-col md="12" class="mb-3">
                      <label for="ss">Foto</label>
                      <div class="form-group">
                        <div class="custom-file">
                          <input type="file" ref="file" class="custom-file-input" id="foto" v-on:change="handleFile()" required>
                          <label class="custom-file-label" for="customFile">{{ formPoto.label }}</label>
                        </div>
                      </div>
                      <template v-if="formPoto.preview">
                        <div class="border p-2 mt-3">
                            <p class="mb-0">Nama Berkas: {{ formPoto.file.name }}</p>
                            <img :src="formPoto.preview" class="img-fluid" />
                        </div>
                      </template>
                    </b-col>
                    
                  </div>
                  <div class="float-right">
                    <button type="submit" class="btn bg-danger mr-1" @click.prevent="formCancel()">Batal</button>
                    <button type="submit" class="btn btn-primary" @click.prevent="updatePoto()">Simpan</button>
                  </div>
                </form>
                </div>
              </div>
            </tab-content-item>
					</tab-content>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import tabNav from '../../../components/tab/tab-nav.vue'
import { showToast } from '../../../_utils/toast'
import { getDataService, putDataService } from '../../../store/modules/crudservices'
import { dateConfig } from '../../../_utils/form-config'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { url_img } from '../../../config/config'

export default {
  name:'PegawaiEdit',
  data() {
    return {
      config: dateConfig.basic,
			formPoto:{
				file: null,
				preview: null,
				label: 'Pilih Foto'
			},
			formProfile:[],
			formPass:{
				pass1: null,
				pass2: null
			},
      jabatan:[
        {
          'golongan': 'I/a',
          'pangkat': 'Juru Muda'
        },
        {
          'golongan': 'I/b',
          'pangkat': 'Juru Muda Tingkat I'
        },
        {
          'golongan': 'I/c',
          'pangkat': 'Juru'
        },
        {
          'golongan': 'I/d',
          'pangkat': 'Juru Tingkat I'
        },
        {
          'golongan': 'II/a',
          'pangkat': 'Pengatur Muda'
        },
        {
          'golongan': 'II/b',
          'pangkat': 'Pengatur Muda Tingkat I'
        },
        {
          'golongan': 'II/c',
          'pangkat': 'Pengatur'
        },
        {
          'golongan': 'II/d',
          'pangkat': 'Pengatur Tingkat I'
        },
        {
          'golongan': 'III/a',
          'pangkat': 'Penata Muda'
        },
        {
          'golongan': 'III/b',
          'pangkat': 'Penata Muda Tingkat I'
        },
        {
          'golongan': 'III/c',
          'pangkat': 'Penata'
        },
        {
          'golongan': 'III/d',
          'pangkat': 'Penata Tingkat I'
        },
        {
          'golongan': 'IV/a',
          'pangkat': 'Pembina'
        },
        {
          'golongan': 'IV/b',
          'pangkat': 'Pembina Tingkat I'
        },
        {
          'golongan': 'IV/c',
          'pangkat': 'Pembina Utama Muda'
        },
        {
          'golongan': 'IV/d',
          'pangkat': 'Pembina Utama Madya'
        },
        {
          'golongan': 'IV/e',
          'pangkat': 'Pembina Utama'
        }
			]
    }
  },
  components:{
    flatPickr, tabNav
  },
  methods: {
    getDataPegawai(id) {
      const url = '/pegawai/' + id;
      getDataService(url).then(response => {
        this.formProfile = response.data
				this.formProfile.path_foto = url_img + response.data.path_foto
      })
    },
		updatePoto(){
      const form = this.formPoto
			
			if (form.file == null) { 
				showToast('error', 'File tidak boleh kosong.')
				return 
			}

			const _formData = new FormData()
      _formData.append('_method', 'PUT' )
      _formData.append('file', form.file)
      
			const url = '/pegawai/' + this.formProfile.id + '/change-photo'
      putDataService(url, _formData).then(response => {
        if(response.data.success) this.$router.push('/master/pegawai')
      })		
		},
    updateProfile(){
      const form = this.formProfile
			const _data = {
				'_method': 'PUT' ,
				'nip': form.nip,
				'jabatan': form.jabatan,
				'full_name': form.full_name,
				'pangkat': form.pangkat,
				'golongan': form.golongan,
				'email': form.email,
				'tgl_lahir': form.tgl_lahir,
				'jenis_kelamin': form.jenis_kelamin,
				'phone': form.phone,
				'address': form.address,
        'status_pegawai': form.status_pegawai,
			}
			
			const url = '/pegawai/' + form.id
      putDataService(url, _data).then(response => {
        if(response.data.success) this.$router.push('/master/pegawai')
      })
    },
    formCancel(){
      this.$router.push('/master/pegawai') 
    },
		handleFile() {
			let input = this.$refs.file
			
			if (input) {
				var reader = new FileReader();
				reader.onload = (e) => {
					this.formPoto.preview = e.target.result;
				}
				this.formPoto.image=input.files[0];
				this.formPoto.file=input.files[0];
				reader.readAsDataURL(input.files[0]);
			}
		},
    golonganHandle(props){
      this.formProfile.golongan = props != null ? props.golongan : null
      this.formProfile.pangkat = props != null ? props.pangkat : null
    }
  },
  mounted() {
    this.getDataPegawai(this.$route.params.id)
    // this.getJabatan()
  }
}
</script>